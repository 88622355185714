import React from 'react'

const RexGreen: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.9971 9.06324V26.2618C35.9971 28.3824 34.5936 30.1409 32.7569 30.3611L3.95923 33.6414C1.8349 33.8817 0 31.9812 0 29.562V5.76298C0 3.34375 1.83672 1.44333 3.95923 1.68361L32.7569 4.96385C34.5936 5.18411 35.9971 6.94437 35.9971 9.06324Z"
        fill="#00E100"
      />
      <path
        d="M30.5071 38.0982C28.7669 38.0982 27.3507 36.682 27.3507 34.9418V33.0122H25.4211C23.6809 33.0122 22.2646 31.596 22.2646 29.8558C22.2646 28.1155 23.6809 26.6993 25.4211 26.6993H27.3507V24.7697C27.3507 23.0295 28.7669 21.6133 30.5071 21.6133C32.2474 21.6133 33.6636 23.0295 33.6636 24.7697V26.6993H35.5931C37.3334 26.6993 38.7496 28.1155 38.7496 29.8558C38.7496 31.596 37.3334 33.0122 35.5931 33.0122H33.6636V34.9418C33.6636 36.682 32.2474 38.0982 30.5071 38.0982Z"
        fill="white"
      />
      <path
        d="M30.5067 22.8626C31.5625 22.8626 32.4144 23.7145 32.4144 24.7703V27.9486H35.5927C36.6485 27.9486 37.5004 28.8005 37.5004 29.8563C37.5004 30.9121 36.6485 31.764 35.5927 31.764H32.4144V34.9423C32.4144 35.9981 31.5625 36.85 30.5067 36.85C29.4509 36.85 28.599 35.9981 28.599 34.9423V31.764H25.4207C24.3649 31.764 23.513 30.9121 23.513 29.8563C23.513 28.8005 24.3649 27.9486 25.4207 27.9486H28.599V24.7703C28.599 23.7145 29.4509 22.8626 30.5067 22.8626ZM30.5067 20.3633C28.0766 20.3633 26.0997 22.3402 26.0997 24.7703V25.4493H25.4207C22.9906 25.4493 21.0137 27.4262 21.0137 29.8563C21.0137 32.2865 22.9906 34.2633 25.4207 34.2633H26.0997V34.9423C26.0997 37.3725 28.0766 39.3494 30.5067 39.3494C32.9369 39.3494 34.9137 37.3725 34.9137 34.9423V34.2633H35.5927C38.0229 34.2633 39.9998 32.2865 39.9998 29.8563C39.9998 27.4262 38.0229 25.4493 35.5927 25.4493H34.9137V24.7703C34.9137 22.3402 32.9369 20.3633 30.5067 20.3633Z"
        fill="#00E100"
      />
      <path
        d="M17.9993 23.6627C21.3129 23.6627 23.9991 20.9765 23.9991 17.6629C23.9991 14.3493 21.3129 11.6631 17.9993 11.6631C14.6857 11.6631 11.9995 14.3493 11.9995 17.6629C11.9995 20.9765 14.6857 23.6627 17.9993 23.6627Z"
        fill="white"
      />
    </svg>
  )
}

export default RexGreen
